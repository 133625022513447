import React from "react";
import { getTrueClasses } from "../../../../../helpers";
import ListBenefits from "./ListBenefits";
import TrackedLink from "../../../../Analytics/TrackedLink";
import "./index.scss";

const Tariff = (props) => {
  const {
    mod,
    period,
    title,
    subTitle,
    payTitle,
    payPeriod,
    discount,
    buyNow,
    software,
    checkoutLanguage,
    paramsLink,
  } = props;
  const eventCategory = `${software ? software : "LANDING"}|Pricing`;
  const updateLink = (link) => {
    paramsLink && (link += paramsLink);
    return checkoutLanguage ? `/${checkoutLanguage}${link}` : link;
  };
  return (
    <div
      className={getTrueClasses("PgIWLBP-PL-T", mod && `PgIWLBP-PL-T_${mod}`)}
    >
      <div className="PgIWLBP-PL-T__period">{period}</div>
      <div className="PgIWLBP-PL-T-block">
        <p className="PgIWLBP-PL-T__title">{title}</p>
        <p className="PgIWLBP-PL-T__subTitle">{subTitle}</p>
        <ListBenefits />
        <div className="PgIWLBP-PL-T__line" />
        <p className="PgIWLBP-PL-T__price">
          {payTitle}
          {payPeriod && <span>{payPeriod}</span>}
        </p>
        <p
          className={getTrueClasses(
            "PgIWLBP-PL-T__discount",
            discount.transparent && "PgIWLBP-PL-T__discount_transparent"
          )}
        >
          {discount.strikethroughText && (
            <strike>{discount.strikethroughText}</strike>
          )}
          {discount.text}
        </p>
        <TrackedLink
          class="PgIWLBP-PL-T__buyNow"
          category={eventCategory}
          action={buyNow.action}
          path={updateLink(buyNow.path)}
          isChild
        >
          {buyNow.text}
        </TrackedLink>
        <p className="PgIWLBP-PL-T__refund">10-day money-back guarantee</p>
      </div>
    </div>
  );
};

export default Tariff;
