const TARIFFS = {
  monthly: {
    mod: "monthly",
    period: "Monthly",
    title: "Listing Builder",
    subTitle:
      "Enhance your product's visibility and secure a top position among Amazon search results with a perfectly created product listing",
    payTitle: "Pay $35.99",
    payPeriod: "/month",
    discount: {
      text: "month",
      transparent: true,
    },
    buyNow: {
      action: "Buy now",
      path: "/checkout/?offerId=listing_builder_monthly",
      text: "Buy now",
    },
  },
  annual: {
    mod: "annual",
    period: "Annual",
    title: "Listing Builder",
    subTitle:
      "Enhance your product's visibility and secure a top position among Amazon search results with a perfectly created product listing",
    payTitle: "Pay $17",
    payPeriod: "/month",
    discount: {
      strikethroughText: "$431.88",
      text: " $199.99 billed annually",
    },
    buyNow: {
      action: "Buy now",
      path: "/checkout/?offerId=listing_builder_annual",
      text: "Buy now",
    },
  },
};
export default TARIFFS;
