import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../../../../helpers";
import "./index.scss";

const Question = ({ tooltip, i }) => (
  <div
    className={getTrueClasses(
      "PgIWLBP-PL-T-LB-Q",
      `PgIWLBP-PL-T-LB-Q_q${i + 1}`
    )}
  >
    <div className="PgIWLBP-PL-T-LB-Q__tooltip">{tooltip}</div>
  </div>
);

Question.propTypes = {
  index: PropTypes.number,
  isComplexContent: PropTypes.bool,
};

export default Question;
