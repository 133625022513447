import React from "react";
import TARIFFS from "../index.content";
import Tariff from "./Tariff";
import "./index.scss";

const PriceList = ({ addProps, paramsLink }) => {
  const { monthly, annual } = TARIFFS;

  return (
    <div className="PgIWLBP-PL">
      <div className="container">
        <p className="PgIWLBP-PL__title">Pricing</p>
        <div className="PgIWLBP-PL__tariffs">
          <Tariff {...monthly} {...addProps} paramsLink={paramsLink} />
          <Tariff {...annual} {...addProps} paramsLink={paramsLink} />
        </div>
      </div>
    </div>
  );
};

export default PriceList;
