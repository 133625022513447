import React from "react";
import { LIST_BENEFITS } from "./index.content";
import SVG_CHECK_MARK_GREEN from "../../../../../../assets/images/vector/components/Paywalls/check_mark_green.svg";
import Question from "./Question";
import "./index.scss";

const ListBenefits = () => {
  return (
    <ul className="PgIWLBP-PL-T-LB">
      {LIST_BENEFITS.map(({ text, tooltip }, i) => (
        <li key={i} className="PgIWLBP-PL-T-LB__item">
          <img
            src={SVG_CHECK_MARK_GREEN}
            alt=""
            className="PgIWLBP-PL-T-LB__checkMark"
          />
          <p className="PgIWLBP-PL-T-LB__text">{text}</p>
          {tooltip && <Question tooltip={tooltip} i={i} />}
        </li>
      ))}
    </ul>
  );
};

export default ListBenefits;
